import React from "react"
import { graphql } from "gatsby"
import Container from "../../components/container"
import Layout from "../../components/layout"
import ClassCard from "../../components/class-card"
import ServicesBlob from "../../components/services-blob"
import Seo from "../../components/seo"

const Classes = ({ data }) => {
  return (
    <Layout>
      {/* Services Section */}
      <div className="text-center pt-24 pb-24 px-4" style={{ zIndex: 30 }}>
        <h1 className=" mb-4 text-6xl mt-0 tracking-normal	">Classes</h1>
        <h2 className="mb-0 normal-case text-3xl tracking-normal font-normal text-lightBlack">
          See our latest offerings
        </h2>
      </div>
      <Container noMax>
        <div
          className="absolute -top-[5%] w-full h-full"
          style={{ zIndex: 10 }}
        >
          <ServicesBlob />
        </div>
        <div
          className="flex flex-col items-center relative  pb-8 max-w-6xl mx-auto mb-12"
          style={{ zIndex: 20 }}
        >
          <div className="mx-4">
            {data.classes2.edges.map(({ node }, index) => (
              <ClassCard
                title={node.data.title.text}
                description={node.data.description.text}
                benefits={node.data.description_benefits.richText.map(
                  bene => bene.text
                )}
                slug={node.url}
                featured_image={node.data.featured_image.gatsbyImageData}
                key={index + 200}
                index={index + 200}
                price={`$${node.data.price}`}
              />
            ))}
            {/* {data.allMarkdownRemark.edges.map(
              ({ node: { frontmatter } }, index) =>
                frontmatter.is_active && (
                  <ClassCard
                    title={frontmatter.title}
                    starting_at_price={frontmatter.starting_at_price}
                    plain_label={frontmatter.plain_label}
                    description={frontmatter.description}
                    benefits={frontmatter.benefits.map(bene => bene.benefit)}
                    slug={`/classes/${frontmatter.slug}`}
                    key={index}
                    index={index}
                    featured_image={frontmatter.featured_image}
                  />
                )
            )} */}
          </div>
        </div>
      </Container>
      {/* <Container noMax>
        <div className="flex flex-col items-center relative pt-48 px-4 pb-32 mx-auto bg-lighterPrimary">
          <h2 className="mb-8 text-center">Benefits of classes</h2>

          <ul className="max-w-3xl">
            {BENEFITS.map(bene => (
              <li
                className="flex items-center rounded-3xl bg-white w-full py-3 px-6 my-3"
                key={bene}
              >
                <CheckMark />
                <span className="ml-2">{bene}</span>
              </li>
            ))}
          </ul>
        </div>
      </Container> */}
    </Layout>
  )
}

export const Head = ({ data }) => {
  return (
    <Seo
      title={"Classes"}
      description={"Dog Training Classes offered in Boise Idaho"}
    >
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "ItemList",
          itemListElement: [
            data.classes2.edges.map(({ node }, index) => ({
              "@type": "ListItem",
              position: 1,
              url: "https://scottsschoolfordogs.com" + node.url,
            })),
          ],
        })}
      </script>
    </Seo>
  )
}

export const query = graphql`
  {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "//classes//" }
        frontmatter: { is_class: { eq: true } }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            featured_image {
              childImageSharp {
                gatsbyImageData
              }
            }
            show_initial_consultation
            is_active
            slug
            description
            why_day_training
            additional_benefits
            pricing_label
            pricing_paragraph
            plain_label
            starting_at_price
            pricing_card_benefits {
              includes
            }
            benefits {
              benefit
            }
          }
        }
      }
    }
    classes2: allPrismicClass(sort: { fields: data___group }) {
      edges {
        node {
          url
          data {
            title {
              text
            }
            description {
              text
            }
            description_benefits {
              richText
            }
            price
            featured_image {
              alt
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

export default Classes
